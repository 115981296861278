var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "details", class: { inSecondHalf: _vm.isInSecondHalf } },
    [
      _c("h5", { staticClass: "quip" }, [_vm._v(_vm._s(_vm.quip))]),
      _c("h4", { staticClass: "blurb" }, [_vm._v(_vm._s(_vm.blurb))]),
      _c("p", { staticClass: "description" }, [
        _vm._v(_vm._s(_vm.description)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }