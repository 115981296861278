<template>
    <div class="details" :class="{ inSecondHalf: isInSecondHalf }">
      <h5 class="quip">{{ quip }}</h5>
      <h4 class="blurb">{{ blurb }}</h4>
      <p class="description">{{ description }}</p>
    </div>
</template>

<script>
export default {
  name: 'FilingTrackerPopup',
  props: {
    quip: String,
    blurb: String,
    description: String,
    isInSecondHalf: Boolean,
  },
}
</script>

<style scoped lang="scss">
$border-color: darken(#283d96, 10%);
$active-color: #5dcac6;
$blocking-color: #e85834;
$font-color: #212529;
$muted-font-color: #494949;
$road-color: #e6e6ed;
$road-width: 3px;
$details-arrow-width: 2rem;
$details-arrow-offset: 3rem;
$details-border-width: 3px;
$details-translate-x: calc($details-arrow-offset + $details-border-width + ($details-arrow-width / 2));

@media (max-width: 735px) {
  .details {
    display: none !important;
  }
}
// desktop styles
@media (min-width: 736px) {
  .step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
    padding-top: 1rem;
    padding-bottom: 2rem;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;

    // inactive state
    .details {
      display: none;
    }

    .icon {
      display: none;
    }

    // active state
    &.active {
      .title {
        color: $active-color;
      }

      .icon {
        display: flex;
      }

      &:hover {
        .details {
          display: flex;
        }
      }
    }

    // blocking state
    &.blocking {
      .quip {
        color: $blocking-color
      }
    }

    // active + blocking state
    &.active.blocking {
      .title {
        color: $blocking-color;
      }
    }
  }

  .details {
    position: absolute;
    top: 100%;
    left: 50%;
    display: flex;
    flex-direction: column;
    width: 23rem;
    padding: 2rem;
    background: white;
    border: $details-border-width solid $border-color;
    color: $font-color;
    text-align: left;
    transform: translateX(calc($details-translate-x * -1));
    z-index: 9999;

    &:before {
      content: "";
      position: absolute;
      top: calc((($details-arrow-width / 2) - ($details-border-width / 2)) * -1);
      left: $details-arrow-offset;
      width: $details-arrow-width;
      height: $details-arrow-width;
      background: white;
      box-shadow: $details-border-width $details-border-width 0 0 $border-color;
      transform: rotate(225deg);
      transform-origin: center;
    }

    &.inSecondHalf {
      left: unset;
      right: 50%;
      transform: translateX($details-translate-x);

      &:before {
        left: unset;
        right: $details-arrow-offset;
      }
    }
  }

  .quip {
    font-size: 0.825rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $active-color;
    margin: 0;
  }

  .blurb {
    font-size: 1.75rem;
    font-weight: 400;
    margin: 0.5rem 0 1rem 0;
  }

  .description {
    margin: 0;
    color: #212529;
    font-size: 0.875rem;
    font-weight: 400;
  }
  .pending-filings-status {
    top: 200%;
    left: -50%;
    .title {
      font-size: 1rem;
    }
  }
  .pending-filings-status::before {
    left: 13rem;
  }
}
</style>
